@font-face {
  font-family: 'Azonix';
  src: url('../../assets/font/AzonixRegular.eot');
  src: url('../../assets/font/AzonixRegular.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/font/AzonixRegular.woff2') format('woff2'),
    url('../../assets/font/AzonixRegular.woff') format('woff'),
    url('../../assets/font/AzonixRegular.ttf') format('truetype'),
    url('../../assets/font/AzonixRegular.svg#AzonixRegular') format('svg');
}

.NavBar {
  font-family: 'Azonix', sans-serif;
}
