@font-face {
  font-family: 'Azonix';
  src: url('../../assets/font/AzonixRegular.eot');
  src: url('../../assets/font/AzonixRegular.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/font/AzonixRegular.woff2') format('woff2'),
    url('../../assets/font/AzonixRegular.woff') format('woff'),
    url('../../assets/font/AzonixRegular.ttf') format('truetype'),
    url('../../assets/font/AzonixRegular.svg#AzonixRegular') format('svg');
}
.overlay {
  position: absolute;
  inset: 0;
  opacity: 0;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.2) 20%,
    rgba(0, 0, 0, 0.41) 79.66%,
    rgba(0, 0, 0, 0.9) 100%
  );
  transition: opacity 0.5s ease-in;
}

.overlay.slide {
  opacity: 1;
}

.card-title {
  font-family: Azonix;
  font-size: 3.0625rem;
}

.card-description {
  font-size: 1rem;
}
.hover-link {
  font-size: 1rem;
  font-family: Azonix;
}

@media screen and (max-width: 768px) {
  .card-title {
    font-size: 1.5rem;
  }
  .card-description,
  .hover-link {
    font-size: 0.75rem;
  }
}

.hover-link:hover {
  cursor: pointer;
}
