/* width */
::-webkit-scrollbar {
  width: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #1b1b1b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #796aff;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4b4296;
}
