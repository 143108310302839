@font-face {
  font-family: 'Azonix';
  src: url('../../assets/font/AzonixRegular.eot');
  src: url('../../assets/font/AzonixRegular.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/font/AzonixRegular.woff2') format('woff2'),
    url('../../assets/font/AzonixRegular.woff') format('woff'),
    url('../../assets/font/AzonixRegular.ttf') format('truetype'),
    url('../../assets/font/AzonixRegular.svg#AzonixRegular') format('svg');
}

.slider-left {
  border-left: 0.3rem solid;
  border-color: rgba(121, 106, 255, 1);
  inset: 0;
  transform: translate(0.025rem, -100%);
  transition: 0.5s ease-in-out;
}
.slider-right {
  border-right: 0.3rem solid;
  border-color: rgba(121, 106, 255, 1);
  inset: 0;
  transform: translate(-0.025rem, 400%);
  transition: 0.5s ease-in-out;
}
.slider-top {
  border-top: 0.3rem solid;
  border-color: rgba(121, 106, 255, 1);
  inset: 0;
  transform: translate(400%, 0.025rem);
  transition: 0.5s ease-in-out;
}
.slider-bottom {
  border-bottom: 0.3rem solid;
  border-color: rgba(121, 106, 255, 1);
  inset: 0;
  transform: translate(-100%, -0.025rem);
  transition: 0.5s ease-in-out;
}
.slider-left-white {
  border-left: 0.2rem solid;
  inset: 0;
  transform: translate(0, -100%);
  transition: 0.5s ease-in-out;
}
.slider-right-white {
  border-right: 0.2rem solid;
  inset: 0;
  transform: translate(0, 400%);
  transition: 0.5s ease-in-out;
}
.slider-top-white {
  border-top: 0.2rem solid;
  inset: 0;
  transform: translate(400%, 0);
  transition: 0.5s ease-in-out;
}
.slider-bottom-white {
  border-bottom: 0.2rem solid;
  inset: 0;
  transform: translate(-100%, 0);
  transition: 0.5s ease-in-out;
}
.slide-left {
  transform: translate(0.025rem, 400%);
  border-color: rgba(121, 106, 255, 1);
}
.slide-top {
  transform: translate(-100%, 0.025rem);
  border-color: rgba(121, 106, 255, 1);
}
.slide-right {
  transform: translate(-0.025rem, -100%);
  border-color: rgba(121, 106, 255, 1);
}
.slide-bottom {
  transform: translate(400%, 0.025rem);
  border-color: rgba(121, 106, 255, 1);
}

.slide-left-white {
  transform: translate(0, 400%);
  border-left-color: rgba(255, 255, 255, 1);
}
.slide-top-white {
  transform: translate(-100%, 0);
  border-color: rgba(255, 255, 255, 1);
}
.slide-right-white {
  transform: translate(0, -100%);
  border-color: rgba(255, 255, 255, 1);
}
.slide-bottom-white {
  transform: translate(400%, 0);
  border-color: rgba(255, 255, 255, 1);
}
.overlay-guest {
  position: absolute;
  inset: 0;
  transform: translate(0, 0%);
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.41) 79.66%,
    rgba(0, 0, 0, 0.9) 100%
  );
  transition: 0.5s linear;
}

.overlay-guest.slide {
  transform: translate(0, 0);
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.9) 100%
  );
}

.guest-title {
  font-family: Azonix;
}
.guest-dept {
  font-family: Azonix;
}
.guest-desgn {
  font-family: Azonix;
}
@media only screen and (max-width: 62em) {
  .guest-register-button {
    width: 100%;
    height: 1rem;
    font-size: 0.625rem;
    display: inline-block;
    padding: 0;
    border: 1px solid white;
    background-color: transparent;
    color: #ffffff;
    text-decoration: none;
    transition: background-color 0.5s ease, color 0.5s ease;
  }
  .overlay-guest {
    transform: translate(0, 0%);
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.6) 20,
      rgba(0, 0, 0, 0.41) 79.66%,
      rgba(0, 0, 0, 0.9) 100%
    );
  }
}
