@font-face {
  font-family: 'Azonix';
  src: url('../../assets/font/AzonixRegular.eot');
  src: url('../../assets/font/AzonixRegular.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/font/AzonixRegular.woff2') format('woff2'),
    url('../../assets/font/AzonixRegular.woff') format('woff'),
    url('../../assets/font/AzonixRegular.ttf') format('truetype'),
    url('../../assets/font/AzonixRegular.svg#AzonixRegular') format('svg');
}

.heading {
  font-family: 'Azonix', sans-serif;
  font-size: 3rem;
}
@media only screen and (max-width: 62em) {
  .heading {
    font-size: 2.25rem;
  }
}

.card-rulebook-button {
  width: 100%;
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid white;
  background-color: transparent;
  color: #ffffff;
  text-decoration: none;
  transition: background-color 0.5s ease, color 0.5s ease;
  font-family: 'Azonix', sans-serif;
}
.card-rulebook-button:hover {
  background-color: #ffffff;
  color: #000000;
}
